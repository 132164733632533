import {Store, createStore, combineReducers} from 'redux'
import {DashboardDtoVM, KullaniciDto, UyeDto} from '../lModel/Modeller'

interface KullaniciState {
  readonly kullaniciData: KullaniciDto
}

interface UyeState {
  readonly uyeData: UyeDto
}
interface DashboardState {
  readonly dashboardData: DashboardDtoVM
}
export interface AppState {
  readonly kullaniciVerisi: KullaniciState
  readonly uyeVerisi: UyeState
  readonly dashboardVerisi: DashboardState
}

const initialDashboardState: DashboardState = {
  dashboardData: {
    BugunKaydolanSayisi: 0,
    BugunSaatlikSayi: [],
    HaftaGunlukSayi: [],
    KaydolanSayisi: 0,
    KaydolanSonUyeler: [],
    TiklayanSayisi: 0,
    TiklayipKaydolmayanSayisi: 0,
    ToplamKaydolmayanSayisi: 0,
    SistemeAktarilanSayisi: 0,
  },
}

const initialKullaniciState: KullaniciState = {
  kullaniciData: {
    KullaniciId21: '',
    TamAdi: '',
    TokenStr: '',
    ResimYolu: '',
    GirisYapti: false,
  },
}

const initialUyeState: UyeState = {
  uyeData: {
    Ad: '',
    Iban: '',
    Notu: '',
    Soyad: '',
    Telefon: '',
    AileEposta: '',
    AileIsim: '',
    Eposta: '',
    IslemAdresi: '',
    KayitTar: '',
    SonTiklanmaTar: '',
    UyeNo: '',
    Adres: '',
    AileTelefon: '',
    DogumYeri: '',
    NLSehir: '',
    PostaKodu: '',
    TRSehir: '',
    SistemeAktarilmaTar: '',
  },
}

export const SETUYEDATA = 'SetUyeData'
export const SETKULLANICIDATA = 'SetKullaniciData'
export const SETDASHBOARDDATA = 'SetDashboardData'

export const setDashboardDataAction = (dashboardData: DashboardDtoVM) =>
  ({
    type: SETDASHBOARDDATA,
    dashboardData: dashboardData,
  } as const)

export const setUyeDataAction = (uyeData: UyeDto) =>
  ({
    type: SETUYEDATA,
    uyeData: uyeData,
  } as const)

export const setKullaniciDataAction = (kullaniciData: KullaniciDto) =>
  ({
    type: SETKULLANICIDATA,
    kullaniciData: kullaniciData,
  } as const)

type KullaniciActions = ReturnType<typeof setKullaniciDataAction>
type UyeActions = ReturnType<typeof setUyeDataAction>
type DashboardActions = ReturnType<typeof setDashboardDataAction>

const dashboardDataReducer = (state = initialDashboardState, action: DashboardActions) => {
  switch (action.type) {
    case SETDASHBOARDDATA: {
      return {
        ...state,
        dashboardData: action.dashboardData,
      }
    }
  }

  return state
}

const uyeDataReducer = (state = initialUyeState, action: UyeActions) => {
  switch (action.type) {
    case SETUYEDATA: {
      return {
        ...state,
        uyeData: action.uyeData,
      }
    }
  }

  return state
}

const kullaniciDataReducer = (state = initialKullaniciState, action: KullaniciActions) => {
  console.log('store', action.kullaniciData)
  switch (action.type) {
    case SETKULLANICIDATA: {
      return {
        ...state,
        kullaniciData: action.kullaniciData,
      }
    }
  }

  return state
}

const rootReducer = combineReducers<AppState>({
  kullaniciVerisi: kullaniciDataReducer,
  uyeVerisi: uyeDataReducer,
  dashboardVerisi: dashboardDataReducer,
})

export function configureStore(): Store<AppState> {
  const store = createStore(rootReducer, undefined)
  return store
}
