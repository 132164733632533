import React, {useEffect, useState} from 'react'
import DataGrid from 'react-data-grid'
import {useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {AppState} from '../../../aStore/Store'
import {KaydolanUyelerDtoVM} from '../../../lModel/Modeller'
import {getKayitlilar} from '../../../lServis'
export const KaydolanlarRpr = () => {
  const [loading, setLoading] = useState(false)
  const [rows, setRows] = useState([] as KaydolanUyelerDtoVM[])
  const klnData = useSelector((state: AppState) => state.kullaniciVerisi)
  const columns = [
    {key: 'UyeNo', name: 'Üye No'},
    {key: 'AdSoyad', name: 'Ad Soyad'},
    {key: 'Tel', name: 'Telefon'},
    {key: 'NLSehir', name: 'Yasadığı Şehir'},
    {key: 'KayitTar', name: 'Kayıt Tarihi'},
  ]

  /* const rows = [
    {
      UyeNo: '123',
      AdSoyad: 'Örnek1',
      Tel: '123',
      NLSehir: 'Lahey',
      KayitTar: '13.11.2021 15:13:45',
    },
  ]*/
  useEffect(() => {
    const doGetKaydolanlarData = async () => {
      const obj = await getKayitlilar(klnData.kullaniciData)
      //console.log('obj', obj)
      const kaydolanUyelerData = obj.Data as KaydolanUyelerDtoVM[]
      console.log('KaydolanUyelerDtoVM', kaydolanUyelerData)
      //setRows(kaydolanUyelerData)
      if (!obj.Success) {
        //alert(obj.Message)
      } else {
        setRows(kaydolanUyelerData)
      }
    }
    doGetKaydolanlarData()
  }, [])
  return (
    <div>
      <Link to='crafted/account/settings/32161'>
        <span>Profile git</span>
      </Link>
      {!loading && <DataGrid columns={columns} rows={rows} />}
      {loading && (
        <span className='indicator-progress' style={{display: 'block'}}>
          Lütfen bekleyiniz...{' '}
          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
        </span>
      )}
    </div>
  )
}
