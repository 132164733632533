/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSSVariableValue} from '../../../assets/ts/_utils'
import {AppState} from '../../../../aStore/Store'
import {useSelector} from 'react-redux'
import {DashboardDtoVM} from '../../../../lModel/Modeller'

type Props = {
  className: string
  chartColor: string
  chartHeight: string
}

const MixedWidget11: React.FC<Props> = ({className, chartColor, chartHeight}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const dashboardData = useSelector((state: AppState) => state.dashboardVerisi)

  const sonHaftaToplamKaydolan = dashboardData.dashboardData.HaftaGunlukSayi.reduce(function (
    sum,
    gun
  ) {
    const updatedSum = sum + gun.KayitSayisi
    return updatedSum
  },
  0)

  useEffect(() => {
    console.log('chartref', -1)
    if (!chartRef.current) {
      console.log('chartref', 0)
      return
    }
    const chartOptions = (chartColor: string, chartHeight: string): ApexOptions => {
      const labelColor = getCSSVariableValue('--bs-gray-500')
      const borderColor = getCSSVariableValue('--bs-gray-200')
      const secondaryColor = getCSSVariableValue('--bs-gray-300')
      const baseColor = getCSSVariableValue('--bs-' + chartColor)
      const KayitOlanData = dashboardData.dashboardData.HaftaGunlukSayi.map((val) => {
        return val.KayitSayisi
      })
      console.log('KayitOlanData', KayitOlanData)
      const TiklayanData = dashboardData.dashboardData.HaftaGunlukSayi.map((val) => {
        return val.TikSayisi
      })
      const LabelData = dashboardData.dashboardData.HaftaGunlukSayi.map((val) => {
        return val.GunAdi
      })
      return {
        series: [
          {
            name: 'Kayıt Olan',
            data: KayitOlanData /*[50, 60, 70, 80, 60, 50, 70]*/,
          },
          {
            name: 'Tıklayan',
            data: TiklayanData,
          },
        ],
        chart: {
          fontFamily: 'inherit',
          type: 'bar',
          height: chartHeight,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '50%',
            borderRadius: 5,
          },
        },
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent'],
        },
        xaxis: {
          categories: LabelData,
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            style: {
              colors: labelColor,
              fontSize: '12px',
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              colors: labelColor,
              fontSize: '12px',
            },
          },
        },
        fill: {
          type: 'solid',
        },
        states: {
          normal: {
            filter: {
              type: 'none',
              value: 0,
            },
          },
          hover: {
            filter: {
              type: 'none',
              value: 0,
            },
          },
          active: {
            allowMultipleDataPointsSelection: false,
            filter: {
              type: 'none',
              value: 0,
            },
          },
        },
        tooltip: {
          style: {
            fontSize: '12px',
          },
          y: {
            formatter: function (val) {
              return val + ''
            },
          },
        },
        colors: [baseColor, secondaryColor],
        grid: {
          padding: {
            top: 10,
          },
          borderColor: borderColor,
          strokeDashArray: 4,
          yaxis: {
            lines: {
              show: true,
            },
          },
        },
      }
    }
    const chart = new ApexCharts(chartRef.current, chartOptions(chartColor, chartHeight))
    if (chart) {
      chart.render()
    }

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, dashboardData.dashboardData.HaftaGunlukSayi])

  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      <div className='card-body p-0 d-flex justify-content-between flex-column overflow-hidden'>
        {/* begin::Hidden */}
        <div className='d-flex flex-stack flex-wrap flex-grow-1 px-9 pt-9 pb-3'>
          <div className='me-2'>
            <span className='fw-bolder text-gray-800 d-block fs-3'>Bu Hafta</span>

            <span className='text-gray-400 fw-bold'>Kayıt ve Tıklanma</span>
          </div>

          <div className={`fw-bolder fs-3 text-${chartColor}`}>{sonHaftaToplamKaydolan} Kayıt</div>
        </div>
        {/* end::Hidden */}

        {/* begin::Chart */}
        <div ref={chartRef} className='mixed-widget-10-chart'></div>
        {/* end::Chart */}
      </div>
    </div>
  )
}

export {MixedWidget11}
