import {KullaniciDto} from '../lModel/Modeller'
import {http} from './http'

import {DataFromServer} from '../lModel/Modeller'
/*export const KullaniciLogin = (kadi: string, sifre: string) => {
  if (kadi === 'admin@demo.com' && sifre === 'demo') {
    const kullanici: KullaniciDto = {
      GirisYapti: true,
      TokenStr: 'deneme',
      KullaniciId21: '123',
      TamAdi: 'Deneme',
      ResimYolu: '',
    }
    return kullanici
  } else {
    const kullanici: KullaniciDto = {
      GirisYapti: false,
      TokenStr: '',
      KullaniciId21: '',
      TamAdi: '',
      ResimYolu: '',
    }
    return kullanici
  }
}*/
export const KullaniciLogin = async (kadi: string, sifre: string): Promise<DataFromServer> => {
  console.log('ser0')
  const result = await http<DataFromServer>({
    path: '/Hdv21Yas/login?kadi=' + kadi + '&sifre=' + sifre,
  })
  console.log('ser1', result)

  if (result.ok && result.body) {
    console.log('ser2', result.body)
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}
