/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'

export function AsideMenuMain() {
  const intl = useIntl()

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title='Ana Sayfa'
        fontIcon='bi-app-indicator'
      />

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>HDV 21 YAŞ</span>
        </div>
      </div>

      <AsideMenuItem
        to='/crafted/account/settings'
        icon='/media/icons/duotune/communication/com006.svg'
        title='Üye Bilgisi'
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='/raporlar'
        icon='/media/icons/duotune/general/gen025.svg'
        title='Raporlar'
        fontIcon='bi-app-indicator'
      />

      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>
    </>
  )
}
