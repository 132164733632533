import {http} from './http'

import {DataFromServer, KullaniciDto} from '../lModel/Modeller'

export const getUyeNodanData = async (
  uyeNo: string,
  kln: KullaniciDto
): Promise<DataFromServer> => {
  const result = await http<DataFromServer>({
    path: '/Hdv21Yas/uyenodangetir?id=' + uyeNo,
    kln: kln,
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}

export const getKayitlilar = async (kln: KullaniciDto): Promise<DataFromServer> => {
  const result = await http<DataFromServer>({
    path: '/Hdv21Yas/kayitlilargetir',
    kln: kln,
  })

  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}

export const setUyeSistemeAktarildi = async (
  uyeId: string,
  kln: KullaniciDto
): Promise<DataFromServer> => {
  console.log('servis', uyeId)
  const result = await http<DataFromServer>({
    path: '/Hdv21Yas/uyesistemeaktarildi?uyeId=' + uyeId,
    kln: kln,
  })
  console.log('result', result)
  if (result.ok && result.body) {
    return result.body
  } else {
    return {
      Data: {},
      Success: false,
      Message: '',
    }
  }
}
