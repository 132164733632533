import React from 'react'
import {MenuItem} from './MenuItem'
import {MenuInnerWithSub} from './MenuInnerWithSub'
import {MegaMenu} from './MegaMenu'
import {useIntl} from 'react-intl'

export function MenuInner() {
  const intl = useIntl()
  return (
    <>
      <MenuItem title='Ana Sayfa' to='/dashboard' />
      <MenuItem title='Üye Bilgisi' to='/crafted/account/settings' />
      <MenuItem title='Raporlar' to='/raporlar' />
    </>
  )
}
