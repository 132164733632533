import React, {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {Redirect, Switch} from 'react-router-dom'
import {setKullaniciDataAction} from '../../../aStore/Store'
import {KullaniciDto} from '../../../lModel/Modeller'

export function Logout() {
  const dispatch = useDispatch()
  useEffect(() => {
    const klnData: KullaniciDto = {
      GirisYapti: false,
      KullaniciId21: '',
      ResimYolu: '',
      TamAdi: '',
      TokenStr: '',
    }
    dispatch(setKullaniciDataAction(klnData))

    document.location.reload()
  }, [dispatch])

  return (
    <Switch>
      <Redirect to='/auth/login' />
    </Switch>
  )
}
