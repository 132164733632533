import React, {useRef} from 'react'
import {KTSVG} from '../../_metronic/helpers/components/KTSVG'
import {KaydolanlarRpr} from './raporlar/KaydolanlarRpr'
export const Raporlar = () => {
  return (
    <div>
      <div className='card card-custom shadow'>
        <div className='card-header card-header-stretch'>
          <h3 className='card-title'>Raporlar</h3>
          <div className='card-toolbar'>
            <ul className='nav nav-tabs nav-line-tabs nav-stretch fs-6 border-0'>
              <li className='nav-item'>
                <a className='nav-link active' data-bs-toggle='tab' href='#kt_tab_pane_7'>
                  Kaydolanlar
                </a>
              </li>
              <li className='nav-item'>
                <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_pane_8'>
                  Rapor 2
                </a>
              </li>
              <li className='nav-item'>
                <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_pane_9'>
                  Rapor 3
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className='card-body'>
          <div className='tab-content' id='myTabContent'>
            <div className='tab-pane fade show active' id='kt_tab_pane_7' role='tabpanel'>
              <KaydolanlarRpr />
            </div>
            <div className='tab-pane fade' id='kt_tab_pane_8' role='tabpanel'></div>
            <div className='tab-pane fade' id='kt_tab_pane_9' role='tabpanel'></div>
          </div>
        </div>
      </div>
    </div>
  )
}
