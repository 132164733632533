import {webAPIUrl} from '../AppSettings'
import {KullaniciDto} from '../lModel/Modeller'

export interface HttpRequest<REQB> {
  path: string
}

export interface HttpResponse<RESB> {
  ok: boolean

  body?: RESB
}
export interface HttpRequest<REQB> {
  path: string

  method?: string

  body?: REQB

  kln?: KullaniciDto
}

export const http = async <RESB, REQB = undefined>(
  config: HttpRequest<REQB>
): Promise<HttpResponse<RESB>> => {
  const request = new Request(
    `${webAPIUrl}${config.path}`,

    {
      method: config.method || 'get',

      headers: {
        'Content-Type': 'application/json',
      },

      body: config.body ? JSON.stringify(config.body) : undefined,
    }
  )

  /* request.headers.set(
    'authorization',

    `${config.accessToken}`
  )*/

  request.headers.set(
    'tokenStr',

    `${config.kln?.TokenStr}`
  )
  request.headers.set(
    'kullaniciId21',

    `${config.kln?.KullaniciId21}`
  )

  const response = await fetch(request)
  const status = response.statusText

  if (response.ok) {
    const body = await response.json()

    return {ok: response.ok, body}
  } else {
    logError(request, response)

    return {ok: response.ok}
  }
}

const logError = async (
  request: Request,

  response: Response
) => {
  const contentType = response.headers.get('content-type')

  let body: any

  if (contentType && contentType.indexOf('application/json') !== -1) {
    body = await response.json()
  } else {
    body = await response.text()
  }

  console.error(
    `Error requesting ${request.method}  
  
       ${request.url}`,

    body
  )
}
